import React, { useContext } from 'react';

import { MenuContext } from '../../context'

const GeneralButton = ({
  onClick = () => { },
  type = 'button',
  children,
  ariaLabel = '',
  isRed,
  isBordered,
  isSquare,
  additionalClass = '',
  isDisabled,
  isTealiumLink
}) => {
  const menuContext = useContext(MenuContext);

  return (
    <button
      className={
        `general-btn ${additionalClass || ''}
        ${((isRed || '') && ' general-btn--red')}
        ${((isBordered || '') && ' general-btn--bordered')}
        ${((isSquare || '') && ' general-btn--square')}`
      }
      type={type}
      aria-label={ariaLabel}
      role="presentation"
      onClick={onClick}
      disabled={isDisabled}
      inert={menuContext.isMenuOpen ? "true" : null}
      {...(isTealiumLink) && { 'data-tealium': '' }}
    >
      {children}
    </button>
  );
}

export default GeneralButton;