import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { MenuContext } from '../../context';
import NewWindow from '../../assets/new_window.inline.svg';

const LiveChatBtn = () => {
  const menuContext = useContext(MenuContext);

  return (
    <span><Link to='https://www.hsbc.co.uk/mortgages/intermediaries/help/'
      className='live-chat-container'
      target='_blank'
      inert={menuContext.isMenuOpen ? 'true' : null}
      data-tealium
    >
      Chat with us
      <NewWindow />
    </Link>
    </span>
  );
};

export default LiveChatBtn;