import React, { useContext } from 'react';

import { MenuContext } from '../../context'

import LINK_SCHEME from './footer-links.utils';

const FooterLinks = ({ links = LINK_SCHEME }) => {
  const menuContext = useContext(MenuContext);

  return (
    <ul className='footer-links'>
      {
        links.map(({ title, href }, index) => (
          <li key={index} data-tealium>
            <a
              href={href}
              inert={menuContext.isMenuOpen ? "true" : null}
              target={href.includes('https://www.hsbc.co.uk/cookie-notice/') ? "_blank" : "_self"}
            >
              {title}
            </a>
          </li>
        ))
      }
    </ul>
  )
}

export default FooterLinks;