import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'gatsby';

import GeneralButton from '../generalButton/generalButton.component';
import MobileMenu from '../mobile-menu/mobile-menu.component';

import Calculator from '../../assets/calculator.inline.svg';
import Files from '../../assets/files.inline.svg';
import Profile from '../../assets/profile.inline.svg';
import NewWindow from '../../assets/new_window.inline.svg';

import { MenuContext } from '../../context';

import { GENERAL_LINK_SCHEME } from './header.utils';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    let curScroll;
    let prevScroll = window.scrollY || window.scrollTop;
    let curDirection = 0;
    let prevDirection = 0;
    let header = document.querySelector('header');
    // Activate the hide function on this point, px.
    const threshold = 200;

    const checkScroll = () => {
      curScroll = window.scrollY || window.scrollTop;
      curDirection = (curScroll > prevScroll) ? 2 : 1;

      if (curDirection !== prevDirection) {
        changeHeader();
      }

      prevScroll = curScroll;
    };

    const changeHeader = () => {
      if (curDirection === 2 && curScroll > threshold) {
        header.classList.add('header--hide');
        prevDirection = curDirection;
      } else if (curDirection === 1) {
        header.classList.remove('header--hide');
        prevDirection = curDirection;
      }
    };

    window.addEventListener('scroll', checkScroll);

  })

  const menuContext = useContext(MenuContext);

  return (
    <header>
      <div className='header-wrapper header-wrapper--black' inert={menuContext.isMenuOpen ? 'true' : null}>
        <div className='header-description header-description--centered wrapper'>
          <div className='main-title-wrapper'>Intermediaries</div>
          <p className='header-description__description'>
            This website is for the use of FCA authorised mortgage <strong>intermediaries only</strong>.
          </p>
          <div className='header-description__tools'>
            <div className='tool-wrapper'>
              <Files />
              <Link
                to="/packaging-requirements"
                activeClassName='active'
              >
                Packaging requirements
              </Link>
            </div>
            <div className='tool-wrapper calculator' inert={menuContext.isMenuOpen ? 'true' : null}>
              <a href='/calculator'
                target='_blank'
                rel='noreferrer'
              >
                <Calculator />
                Launch calculators
                <NewWindow />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='header-wrapper' inert={menuContext.isMenuOpen ? 'true' : null}>
        <div className='header-description header-description--no-padding wrapper'>
          <div className='header-description__mobile-menu'>
            <MenuContext.Consumer>
              {({ isMenuOpen, toggleMenu }) => (
                <button
                  className='mobile-menu-button'
                  aria-label="Toggle navigation"
                  role="presentation"
                  tabIndex="0"
                  onClick={() => {
                    setIsMobileMenuOpen(true);
                    toggleMenu();
                  }}
                  inert={menuContext.isMenuOpen ? 'true' : null}
                >
                  <span className='line'></span>
                  <span className='line'></span>
                  <span className='line'></span>
                </button>
              )}
            </MenuContext.Consumer>
          </div>
          <Link className='logo' aria-label="HSBC UK homepage" to='/'
            inert={menuContext.isMenuOpen ? 'true' : null}
          ></Link>
          <nav className='link-container'>
            {
              GENERAL_LINK_SCHEME.map(({ mainTitle, innerTitle, link, isLink, isBlank }) => (
                <React.Fragment key={mainTitle}>
                  {isLink ? (
                    <Link className='navigation-item' to={link}>
                      <span className="main">{mainTitle}</span>
                      <span className="second">{innerTitle}</span>
                    </Link>
                  ) : (
                    <Link
                      className='navigation-item'
                      to={link}
                      target={isBlank ? '_blank' : '_self'}
                      rel={isBlank ? 'noreferrer' : undefined}
                    >
                      <span className="main">{mainTitle}</span>
                      <span className="second">{innerTitle}</span>
                    </Link>
                  )
                  }
                </React.Fragment>
              ))
            }
          </nav>
          <div className='header-description__user-action-container'>
            <GeneralButton
              additionalClass='profile-btn'
              ariaLabel="Register to the website"
              onClick={() => {
                window.location.href = 'https://platform.intermediaries.hsbc.co.uk/register';
              }}
            >
              <Profile /> Register
            </GeneralButton>
            <GeneralButton
              additionalClass='log-in'
              ariaLabel="Log on to the website"
              isRed
              onClick={() => {
                window.location.href = 'https://platform.intermediaries.hsbc.co.uk/CSSO/LoginRequest?ReturnUrl=/login/';
              }}
            >
              Log on
            </GeneralButton>
          </div>
        </div>
      </div>
      <MobileMenu
        setIsOpen={setIsMobileMenuOpen}
        isOpen={isMobileMenuOpen}
      />
    </header>
  );
};

export default Header;